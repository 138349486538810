import { ACTION, actionMap } from './actionMap';
import { IBaseGridActionListItem } from '@/components/base/grid/typings/interface/IBaseGridActionListItem';

const batchGridActionList: Array<IBaseGridActionListItem> = [
  {
    ...actionMap.get(ACTION.CONTEXT_MENU),
    childActionList: [
      actionMap.get(ACTION.ADD),
      actionMap.get(ACTION.EDIT),
      actionMap.get(ACTION.DELETE),
    ],
  },
];

export { batchGridActionList };
