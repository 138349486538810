














































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { batchGridActionList } from '@/utils/gridActions/batchGridActionList';
import {
  batchRouteEdit,
  batchRouteNew,
  batchRouteShow,
} from '@/views/accounting/batch/routes';
import { MBatch } from '@/models/MBatch';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinPDF } from '@/components/mixins/pdf';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'BatchListShow',

  components: {
    BaseButtonText,
    BaseGridTable,
    BaseSelect,
    LayoutPage,
  },

  mixins: [
    mixinAlert,
    mixinModal,
    mixinPDF,
  ],

  data() {
    return {
      currentYear: new Date().getFullYear(),

      gridActionList: batchGridActionList,

      gridColumnDefinition: [
        {
          headerName: this.$tc('batch.property.batchNumber'),
          field: 'batchNumber',
        },
        {
          headerName: this.$tc('batch.property.productName'),
          field: 'productName',
        },
        {
          headerName: this.$tc('batch.property.entity'),
          field: 'entity',
        },
        {
          headerName: this.$tc('batch.property.quantity'),
          field: 'quantity',
        },
        {
          headerName: this.$tc('batch.property.date'),
          field: 'date',
        },
      ],
      yearList: [],
    };
  },

  computed: {
    batchList(): Array<MBatch> {
      return MBatch.all();
    },

    displayedYear(): Object {
      return {
        year: this.currentYear,
      };
    },

    gridRowData(): Array<any> {
      return this.batchList.map(
        batch => batch.asListData,
      );
    },
  },

  methods: {
    add(): void {
      this.$router.push(batchRouteNew(this.$i18n.locale));
    },

    closeModal(): void {
      this.$router.push(batchRouteShow(this.$i18n.locale));
    },

    edit(batchID: string): void {
      this.$router.push({
        name: batchRouteEdit(this.$i18n.locale).name,

        params: {
          ID: batchID,
        },
      });
    },

    exportPdf(): void {
      this.exportData(this.gridRowData, this.gridColumnDefinition,
        `${this.$tc('batch.model', 2)}-${this.currentYear}`);
    },

    async loadYear(year: any): Promise<any> {
      this.currentYear = year.year;

      await MBatch._fetch();
    },

    async remove(batchID: string): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$tc('charge.alert.delete', {
          BatchNumber: MBatch.find(batchID).BatchNumber,
        }),
      );

      if (confirmation.value) {
        MBatch._delete(batchID);
      }
    },
  },

  async beforeMount(): Promise<any> {
    this.yearList = await MBatch._getYears();
  },
});
