









































































import BaseDatePicker from '@/components/base/BaseDatePicker.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import Vue, { VueConstructor } from 'vue';
import { endOfDay } from 'date-fns';
import { MBatch } from '@/models/MBatch';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinSync } from '@/components/mixins/sync';
import { MProduct } from '@/models/MProduct';

export default (Vue as VueConstructor).extend({
  name: 'BatchEdit',

  components: {
    BaseDatePicker,
    BaseInputField,
    BaseSelect,
    BaseSideLabel,
    LayoutModalEdit,
    LayoutHeader,
  },

  mixins: [
    mixinAlert,
    mixinModal,
    mixinModes,
    mixinSync,
  ],

  props: {
    ID: {
      type: String,
      required: true,
    },
  },

  computed: {
    productList() {
      return MProduct.query().where((product) => {
        return product.ArticleNumber.includes('GZ');
      }).get();
    },

    currentBatch: {
      get(): MBatch {
        return MBatch.find(this.ID);
      },

      async set(batch: MBatch) {
        this.dirty = true;

        await MBatch.update({
          where: this.ID,

          data: batch,
        });
      },
    },

    currentBatchDate() {
      return this.currentBatch.Date;
    },
  },

  methods: {
    updateBatchNumber(Number: number) {
      this.currentBatch = {
        ...this.currentBatch,
        BatchNumber: Number,
      };
    },

    updateBatchDate(data): void {
      this.currentBatch = {
        ...this.currentBatch,
        Date: endOfDay(new Date(data)).toISOString(),
      };
    },

    closeModal(): void {
      this.$router.push(this.routeBack);
    },

    async remove(): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$tc('batch.alert.delete', {
          batchNumber: this.currentBatch.BatchNumber,
        }),
      );

      if (confirmation.value) {
        MBatch._delete(this.currentBatch.Id);

        this.closeModal();
      }
    },

    sync(): void {
      MBatch._update(this.currentBatch);
    },

    updateBatch(Prop, Value): void {
      if (this.currentBatch[Prop] !== Value) {
        this.currentBatch = {
          ...this.currentBatch,
          [`${Prop}`]: Value,
        };
      }
    },

    updateBatchProduct(Value): void {
      if (this.currentBatch.ProductID !== Value.Id) {
        this.currentBatch = {
          ...this.currentBatch,
          ProductID: Value.Id,
          ProductName: Value.Name,
        };
      }
    },
  },
});
